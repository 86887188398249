import { createStore } from 'easy-peasy';

import { auth, problems } from './models';
import { StoreModel } from './types';

export const store = createStore<StoreModel>({
  auth,
  problems
});

export * from './hooks';
