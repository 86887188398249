import { useStoreActions, useStoreState } from 'easy-peasy';
import { StoreActionsType, StoreStateType } from 'store/types';

export const useProblemsState = () => {
  return useStoreState((store: StoreStateType) => store.problems);
};

export const useProblemsActions = () => {
  return useStoreActions((store: StoreActionsType) => store.problems);
};
