import { css } from '@emotion/react';
import { Button, ButtonProps } from '@shepper-tech/shepper-ui';
import { FC, SyntheticEvent, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

export type ConfirmProps = {
  title: string;
  text: string;
  onConfirm: (close: () => void) => void;
} & ButtonProps;

export const ConfirmButton: FC<ConfirmProps> = props => {
  const { title, text, onConfirm, children, ...other } = props;

  const [isOpen, setIsOpen] = useState(false);

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  const handleOnConfirm = (event: SyntheticEvent) => {
    event.preventDefault();

    onConfirm(close);
  };

  return (
    <>
      <Button onClick={open} {...other}>
        {children}
      </Button>
      {isOpen && (
        <SweetAlert title={title} onConfirm={handleOnConfirm} showConfirm={false} onCancel={close}>
          {text}
          <div css={btnGroupStyles}>
            <Button css={btnStyles} color="default" size="large" fullWidth onClick={close}>
              Cancel
            </Button>
            <Button css={btnStyles} color="primary" size="large" fullWidth onClick={handleOnConfirm}>
              Confirm
            </Button>
          </div>
        </SweetAlert>
      )}
    </>
  );
};

const btnGroupStyles = css`
  display: flex;
  margin-top: 20px;
  width: 100%;
  justify-content: space-between;
`;

const btnStyles = css`
  padding: 18px;
  width: calc(50% - 10px);
`;
