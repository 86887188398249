import { css } from '@emotion/react';
import { Button, Image, Text } from '@shepper-tech/shepper-ui';
import { ReactComponent as MapFilledIcon } from 'assets/icons/map-filled.svg';
import { ReactComponent as PhotoIcon } from 'assets/icons/photo.svg';
import { FC } from 'react';
import { Location } from 'types';
import { formatDate } from 'utils';

export interface LocationCardProps {
  data: Location;
  onDetails: () => void;
}

export const LocationCard: FC<LocationCardProps> = props => {
  const { data, onDetails } = props;

  return (
    <div css={rootStyles}>
      <Image css={coverStyles} src={data.image_url} alt={`${data.business_name}-${data.reference}`} />

      <div css={bodyStyles}>
        <Text css={{ fontWeight: 700 }} variant="large">
          {data.business_name} / {data.reference}
        </Text>
        <Text>{data.address}</Text>

        <div css={rowStyles}>
          <div css={rowStyles}>
            <MapFilledIcon css={iconStyles} />
            <Text css={{ fontWeight: 700 }} variant="large">
              {formatDate(data.last_reported_at)}
            </Text>
          </div>

          <div css={rowStyles}>
            <PhotoIcon css={iconStyles} />
            <Text css={{ fontWeight: 700 }} variant="large">
              {data.visit?.problems.length} problems
            </Text>
          </div>
        </div>

        <Button css={detailBtnStyles} size="large" fullWidth onClick={onDetails}>
          Learn More
        </Button>
      </div>
    </div>
  );
};

const rootStyles = css`
  display: flex;
  flex-direction: column;

  width: 100%;

  border: 1px solid #dcdcdc;
  border-radius: 8px;

  overflow: hidden;
`;

const bodyStyles = css`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 12px;
`;

const coverStyles = css`
  max-height: 130px;
  object-fit: cover;
`;

const rowStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5em;
`;

const iconStyles = css`
  height: 20px;
`;

const detailBtnStyles = css`
  padding: 12px;
  margin-top: 1em;

  font-weight: 700;

  color: #283241;
  border-color: #283241;
`;
