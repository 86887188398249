import { AxiosRequestConfig, AxiosResponse } from 'axios';
import flatten from 'flat';
import { client, isClientError } from 'utils';

import { Errors } from './Errors';
import { FormMethod } from './FormMethod';

export type IFormData = Record<string, unknown>;

export interface FormRequestParams<T, R = unknown> {
  url?: string;
  method?: FormMethod;
  service?: (request: R) => Promise<AxiosResponse<T>>;
}

export class Form<D = IFormData> {
  [key: string]: unknown;

  /**
   * A key value pair object holding the form data.
   */
  public data: D;

  /**
   * A callback hook called right after the form.submit() is called.
   *
   * @private
   */
  private onSubmitCallback: ((form: Form<D>) => void) | undefined;

  /**
   * A boolean status indicating whether a http request is
   * being processed ot not.
   *
   * @private
   */
  private processing = false;

  /**
   * The error bag containing all the field validation errors.
   */
  public errors: Errors;

  /**
   * @param {IFormData} data
   */
  public constructor(data: D = {} as D) {
    this.data = { ...data };
    this.errors = new Errors();
  }

  /**
   * It makes a GET request to given url sending the form data
   * as parameters.
   *
   * @param {string} url
   */
  public get<T>(url: string) {
    return this.request<T>({ method: FormMethod.GET, url });
  }

  /**
   * It makes a POST request to given url sending the form data
   * as part of the request body.
   *
   * @param {string} url
   */
  public post<T>(url: string) {
    return this.request<T>({ method: FormMethod.POST, url });
  }

  /**
   * It makes a PUT request to given url sending the form data
   * as part of the request body.
   *
   * @param {string} url
   */
  public put<T>(url: string) {
    return this.request<T>({ method: FormMethod.PUT, url });
  }

  /**
   * It makes a DELETE request to given url sending the form data
   * as part of the request body.
   *
   * @param {string} url
   */
  public delete<T>(url: string) {
    return this.request<T>({ method: FormMethod.DELETE, url });
  }

  /**
   * Base method to makes http requests with the form data to given
   * url and method (HTTP verb).
   *
   * @param {FormRequestParams} params
   */
  public request<T, R = unknown>({ method, url, service }: FormRequestParams<T, R>) {
    if (!service && (!method || !url)) {
      throw new TypeError('Form: Expected method and url or valid service');
    }

    this.errors.clear();
    this.processing = true;

    return new Promise((resolve, reject) => {
      const config: AxiosRequestConfig = {
        method,
        url
      };

      // For GET request we send the data as parameters.
      // otherwise we send it as part of the request body.
      if (method === FormMethod.GET) {
        config.params = this.data;
      } else {
        config.data = this.hasFiles() ? this.toFormData() : this.data;
      }

      (service instanceof Function ? service(this.data as unknown as R) : client.request<T>(config))
        .then(response => {
          if (isClientError(response)) {
            if (response?.status === 422) {
              this.errors.record(response.errorData?.error.fields);
            }
            reject(response);
          } else {
            resolve(response.data);
          }
        })
        .finally(() => {
          this.processing = false;
        });
    });
  }

  /**
   * It returns a flatted version of the form data using dot notation
   * for nested data.
   */
  public flattedData() {
    return flatten(this.data);
  }

  /**
   * It returns a boolean indicating whether the form data contains
   * files or not.
   */
  public hasFiles() {
    const data = this.flattedData();

    // @ts-ignore
    const file = Object.values(data).find((field: unknown) => {
      return field instanceof File;
    });

    return file !== undefined;
  }

  /**
   * It returns the form data as a FormData object used to
   * send http requests with files.
   *
   * @protected
   */
  protected toFormData() {
    const formData = new FormData();
    const data = this.flattedData();

    // @ts-ignore
    Object.keys(data).forEach((fieldKey: string) => {
      const transformedKey = fieldKey.split('.').reduce((carry: string, key: string, index: number) => {
        return index === 0 ? key : `${carry}[${key}]`;
      }, '');

      // @ts-ignore
      formData.append(transformedKey, data[fieldKey]);
    });

    return formData;
  }

  /**
   * Submit trigger to call the submit callback.
   */
  public submit() {
    if (this.onSubmitCallback) {
      this.onSubmitCallback(this);
    }
  }

  /**
   * Submit callback setter.
   *
   * @param callback
   */
  public onSubmit(callback: (updatedForm: Form<D>) => void) {
    this.onSubmitCallback = callback;
  }

  /**
   * It returns a copy of the instance. Useful when you want
   * javascript to handle it as a brand new instance.
   */
  public copy(): Form<D> {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  /**
   * Processing state getter.
   */
  public isProcessing() {
    return this.processing;
  }
}
