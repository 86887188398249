import { css } from '@emotion/react';
import { FC } from 'react';
import { Location } from 'types';
import { formatDate } from 'utils';

interface LocationMarkerProps {
  lat: string;
  lng: string;
  location: Location;
  onClick: () => void;
}

const scoreColorMap = {
  0: '#000000',
  1: '#00de9e',
  2: '#8cf0d2',
  3: '#ffd4a2',
  4: '#ffc37e',
  5: '#ffaa46',
  6: '#ff553f'
};

export const LocationMarker: FC<LocationMarkerProps> = props => {
  const { lat, lng, location, onClick } = props;

  return (
    <div css={rootStyles} data-lat={lat} data-lng={lng} onClick={onClick}>
      <div
        css={cardStyles}
        style={{
          borderColor: scoreColorMap[location.score],
          backgroundColor: scoreColorMap[location.score]
        }}
      >
        <div className="score">{location.score}</div>
        <div className="details">
          <div className="row">
            <span className="field">Score:</span>
            <span className="value">{location.score}</span>
          </div>
          <div className="row">
            <span className="field">Store:</span>
            <span className="value">{location.reference}</span>
          </div>
          <div className="row">
            <span className="field">Last visit:</span>
            <span className="value">{formatDate(location.last_reported_at, 'DD/MM')}</span>
          </div>
          <div className="row">
            <span className="field">Status:</span>
            <span className="value">Problems ({location.visit?.problems.length})</span>
          </div>
        </div>
        <div css={[triangleStyles]} style={{ borderTop: `10px solid ${scoreColorMap[location.score]}` }}>
          <div className="inside-triangle" />
        </div>
      </div>
    </div>
  );
};

const rootStyles = css`
  position: relative;
  width: 168px;
`;

const cardStyles = css`
  display: flex;
  position: absolute;
  bottom: 0;
  left: -50%;
  width: auto;
  height: auto;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #000000;
  font-family: Effra;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 110%;

  .score {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20px;
    color: #ffffff;
    height: 100%;
  }

  .details {
    display: flex;
    flex-direction: column;
    width: 148px;
    padding: 5px;
    background-color: #ffffff;

    .row {
      display: flex;
      flex-direction: row;
      padding-bottom: 2px;
    }

    .field {
      display: flex;
      width: 63px;
    }

    .value {
      display: flex;
      flex-wrap: wrap;
      width: auto;
      font-weight: bold;
    }
  }
`;

const triangleStyles = css`
  position: absolute;
  bottom: -10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-top: 10px solid #000000;

  .inside-triangle {
    position: absolute;
    top: -11px;
    left: -9px;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;

    border-top: 9px solid #ffffff;
  }
`;
