import { Button, ButtonColor, Input, Text, useTheme } from '@shepper-tech/shepper-ui';
import { FC, FormEvent } from 'react';

import * as styles from '../styles';
import { ResetPasswordForm } from '../types';

export interface ResetPasswordProps {
  form: ResetPasswordForm;
}

export const ResetPassword: FC<ResetPasswordProps> = props => {
  const { form } = props;

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    form.submit();
  };

  const { palette } = useTheme();

  return (
    <>
      <Text css={styles.titleStyles} variant="xxLargePlus">
        Set a new password
      </Text>

      <form css={styles.formStyles} onSubmit={handleSubmit}>
        <div css={styles.fieldWrapper}>
          <Input
            css={styles.inputStyles}
            onChange={event => {
              form.email = event.currentTarget.value;
            }}
            value={form.email}
            error={form.errors.first('email')}
            inputProps={{ placeholder: 'Enter your email address' }}
          />
          {form.errors.any('email') && (
            <Text css={[styles.errorMessage, { color: palette.error }]} variant="medium">
              {form.errors.first('email')}
            </Text>
          )}
        </div>

        <div css={styles.fieldWrapper}>
          <Input
            css={styles.inputStyles}
            onChange={event => {
              form.password = event.currentTarget.value;
            }}
            value={form.password}
            error={form.errors.first('password')}
            inputProps={{ placeholder: 'Enter a new password', type: 'password', minLength: 6 }}
          />
          {form.errors.any('password') && (
            <Text css={[styles.errorMessage, { color: palette.error }]} variant="medium">
              {form.errors.first('password')}
            </Text>
          )}
        </div>

        <Button
          type="submit"
          color={ButtonColor.primary}
          fullWidth
          disabled={form.isProcessing()}
          css={styles.actionButtonStyles}
        >
          Reset password
        </Button>
      </form>
    </>
  );
};
