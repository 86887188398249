import 'react-spring-bottom-sheet/dist/style.css';

import { css } from '@emotion/react';
import { Button, Image, Progress, Text } from '@shepper-tech/shepper-ui';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ConfirmButton, IconButton } from 'components';
import { FC, memo, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';
import { useProblemsActions, useProblemsState } from 'store';
import { useDebounce } from 'use-debounce';

import { VisitStatus } from '../../../../types';
import { formatDate } from '../../../../utils';
import { Notes } from '../Notes';
import { Problem } from '../Problem';

export interface DetailViewProps {
  id?: string;
}

export const DetailView: FC<DetailViewProps> = memo(props => {
  const { id } = props;

  const history = useHistory();

  const { location } = useProblemsState();
  const { fetchLocation, fixLocation, flagIncorrectLocation } = useProblemsActions();
  const [fetchLocationDebounced] = useDebounce(fetchLocation, 600);

  const sheetRef = useRef<BottomSheetRef>(null);
  const focusRef = useRef<HTMLButtonElement>(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!id || !isReady) return;
    fetchLocationDebounced(id);
  }, [fetchLocationDebounced, id, isReady]);

  const open = !!id;
  const isLoading = location.isLoading || !isReady;

  const [isArrived, setIsArrived] = useState(false);

  const handleNotesSubmit = (notes: string) => {
    fixLocation({ notes }).then(() => {
      history.goBack();
    });
  };

  const handleFlagIncorrectLocation = (close: () => void) => {
    flagIncorrectLocation()
      .then(() => {
        history.goBack();
      })
      .catch(() => {
        close();
      });
  };

  return (
    <BottomSheet
      open={open}
      ref={sheetRef}
      initialFocusRef={focusRef}
      defaultSnap={({ snapPoints }) => Math.max(...snapPoints)}
      snapPoints={({ maxHeight }) => [maxHeight - maxHeight / 10]}
      onSpringStart={() => !open && setIsReady(false)}
      onSpringEnd={() => open && setIsReady(true)}
      header={
        <div css={headerStyles}>
          <Text css={{ fontWeight: 700 }} variant="xLargePlus">
            {!isLoading && (
              <>
                {location.data?.business_name} / {location.data?.reference}
              </>
            )}
          </Text>
          <IconButton onClick={() => history.goBack()}>
            <CloseIcon />
          </IconButton>
        </div>
      }
      footer={
        <div>
          {!isLoading && location.data?.visit?.status === VisitStatus.completed && (
            <div css={completedVisitStyles}>
              <Text css={{ fontWeight: 700 }} variant="xLargePlus">
                Last visit completed on {formatDate(location.data?.visit?.ended_at, 'DD/MM')}
                {location.data?.visit?.user &&
                  ` by ${location.data?.visit?.user.first_name} ${location.data?.visit?.user.last_name}`}
              </Text>
              <Text css={notesStyles} variant="large">
                {location.data?.visit?.notes}
              </Text>
            </div>
          )}
          {!isLoading && location.data?.visit?.status === VisitStatus.incorrectLocation && (
            <div css={completedVisitStyles}>
              <Text css={{ fontWeight: 700 }} variant="xLargePlus">
                Location flagged as incorrect on {formatDate(location.data?.visit?.ended_at, 'DD/MM')}
                {location.data?.visit?.user &&
                  ` by ${location.data?.visit?.user.first_name} ${location.data?.visit?.user.last_name}`}
              </Text>
              <Text css={notesStyles} variant="large">
                {location.data?.visit?.notes}
              </Text>
            </div>
          )}
          {location.data?.visit?.status === VisitStatus.required && (
            <div css={btnGroupStyles}>
              <ConfirmButton
                color="default"
                size="large"
                fullWidth
                css={btnStyles}
                title="Are you sure you this location is incorrect?"
                text="This action cannot be undone and you won't be able to send your notes and complete the visit."
                onConfirm={handleFlagIncorrectLocation}
              >
                Incorrect location
              </ConfirmButton>
              <Button
                css={btnStyles}
                color="primary"
                size="large"
                fullWidth
                disabled={isLoading}
                onClick={() => setIsArrived(true)}
              >
                I have arrived
              </Button>
            </div>
          )}
        </div>
      }
    >
      <div css={rootStyles}>
        {isLoading ? (
          <Progress css={{ marginTop: '5em' }} />
        ) : (
          <div>
            <div css={primaryImageWrapperStyles}>
              <Image
                css={primaryImageStyles}
                // @ts-ignore
                src={location.data?.image_url}
                alt={`${location.data?.business_name}-${location.data?.reference}`}
              />
            </div>
            <div css={problemsWrapperStyles}>
              {location.data?.visit?.problems.map(problem => (
                <Problem key={problem.id} data={problem} />
              ))}
              <Notes isOpen={isArrived} onClose={() => setIsArrived(false)} onSubmit={handleNotesSubmit} />
            </div>
          </div>
        )}
      </div>
    </BottomSheet>
  );
});

const rootStyles = css`
  display: flex;
  flex-direction: column;
  padding: 0 18px 18px 18px;
  height: 100%;
`;

const headerStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
`;

const primaryImageWrapperStyles = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px 0px;
`;

const primaryImageStyles = css`
  max-height: 500px;
  border-radius: 6px;
  object-fit: cover;
`;

const problemsWrapperStyles = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 24px;
  overflow-y: auto;
`;

const btnGroupStyles = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const btnStyles = css`
  padding: 18px;
  width: calc(50% - 10px);
`;

const completedVisitStyles = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 18px;
`;
const notesStyles = css`
  font-weight: 400;
  overflow-y: auto;
  max-height: 300px;
  padding-top: 10px;
`;
