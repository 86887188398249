import { css } from '@emotion/react';
import { Card, Image, Logo, Text, useTheme } from '@shepper-tech/shepper-ui';
import camelotLogo from 'assets/images/camelot-logo.png';
import { FC } from 'react';

export const Layout: FC = props => {
  const { children } = props;

  const { palette } = useTheme();

  return (
    <div css={[wrapperStyles, { background: palette.primary }]}>
      <div css={logosWrapper}>
        <Image src={camelotLogo} alt="camelot logo" />

        <div css={poweredByWrapper}>
          <Text css={{ color: '#fff' }} variant="large">
            Powered by
          </Text>
          <Logo isWhite css={{ width: 90 }} />
        </div>
      </div>

      <Card css={cardStyles}>{children}</Card>
    </div>
  );
};

const wrapperStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  height: 100%;
  width: 100%;
`;

const logosWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  margin-bottom: 80px;
`;
const poweredByWrapper = css`
  display: flex;
  gap: 5px;
`;

const cardStyles = css`
  align-items: flex-start;

  width: 100%;
  max-width: 600px;
  padding: 30px 24px 70px 24px;

  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;
