import { css } from '@emotion/react';
import { Button, ButtonProps } from '@shepper-tech/shepper-ui';
import { FC } from 'react';

export type IconButtonProps = ButtonProps;

export const IconButton: FC<IconButtonProps> = props => {
  const { children, ...other } = props;

  return (
    <Button css={rootStyles} {...other}>
      {children}
    </Button>
  );
};

const rootStyles = css`
  border: 0;
  padding: 10px;
  border-radius: 16px;

  background: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1);

  svg {
    width: 20px;
    height: 20px;
  }
`;
