import { Thunk, thunk } from 'easy-peasy';
import { toast } from 'react-hot-toast';
import { locationsService } from 'services';
import { getLoadable, getSimpleSetState } from 'store/helpers';
import { ILoadable, ISimpleModel, StoreModel } from 'store/types';
import { FetchLocationsRequest, Location, Locations } from 'types';
import { getCurrentPosition } from 'utils';

export interface ProblemsModel extends ISimpleModel<ProblemsModel> {
  location: ILoadable<Location>;
  fetchLocation: Thunk<ProblemsModel, string>;
  fixLocation: Thunk<ProblemsModel, { notes: string }, unknown, StoreModel, Promise<unknown>>;
  flagIncorrectLocation: Thunk<ProblemsModel, never, unknown, StoreModel, Promise<unknown>>;
  locations: ILoadable<Locations>;
  fetchLocations: Thunk<ProblemsModel, FetchLocationsRequest, unknown, StoreModel>;
}

export const problems: ProblemsModel = {
  setState: getSimpleSetState<ProblemsModel>(),

  location: getLoadable(),

  locations: getLoadable(),

  fetchLocation: thunk(async (actions, payload) => {
    actions.setState({ key: 'location', value: getLoadable({ isLoading: true }) });

    const response = await locationsService.getLocation(payload);

    actions.setState({ key: 'location', value: getLoadable({ data: response.data ?? null }) });
  }),

  fixLocation: thunk(async (actions, { notes }, { getState }) => {
    try {
      const { location, locations } = getState();

      if (!location.data?.id) {
        throw new Error('Location id is empty');
      }

      const position = await getCurrentPosition();

      await locationsService.fixLocation(location.data?.id, {
        notes,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      });

      const locationsData = locations.data?.data.filter(i => i.id !== location.data?.id);

      actions.setState({ key: 'locations', value: { ...locations, data: { ...locations.data, data: locationsData } } });

      toast.success('Notes has been sent successfully.');
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      // eslint-disable-next-line no-console
      if (error) console.error(error);
    }
  }),

  flagIncorrectLocation: thunk(async (actions, payload, { getState }) => {
    try {
      const { location, locations } = getState();

      if (!location.data?.id) {
        throw new Error('Location id is empty.');
      }

      await locationsService.flagIncorrectLocation(location.data?.id);

      const locationsData = locations.data?.data.filter(i => i.id !== location.data?.id);

      actions.setState({ key: 'locations', value: { ...locations, data: { ...locations.data, data: locationsData } } });

      toast.success('The location has been flagged as incorrect successfully.');
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      // eslint-disable-next-line no-console
      if (error) console.error(error);
    }
  }),

  fetchLocations: thunk(async (actions, payload, { getStoreState }) => {
    const { problems } = getStoreState();

    actions.setState({ key: 'locations', value: getLoadable({ data: problems.locations.data, isLoading: true }) });

    const response = await locationsService.getLocations({ ...payload });

    actions.setState({ key: 'locations', value: getLoadable({ data: response.data ?? null }) });
  })
};
