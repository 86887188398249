import { css } from '@emotion/react';
import { FC } from 'react';

interface ClusterMarkerProps {
  lat: string;
  lng: string;
  points: unknown[];
  pointCount: number;
  onClick: () => void;
}

export const ClusterMarker: FC<ClusterMarkerProps> = props => {
  const { pointCount, onClick, lat, lng, points } = props;

  return (
    <div
      data-lat={lat}
      data-lng={lng}
      onClick={onClick}
      css={rootStyles}
      style={{
        width: `${20 + (pointCount / points.length) * 40}px`,
        height: `${20 + (pointCount / points.length) * 40}px`
      }}
    >
      {pointCount}
    </div>
  );
};

const rootStyles = css`
  color: #fff;
  background: #61d3d8;
  font-family: Effra;
  border: 2px solid #fff;
  font-weight: bold;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
