import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { ACCESS_TOKEN_KEY, ENTRY_POINT } from 'consts/api';
import { toast } from 'react-hot-toast';

export function getAccessToken(): string | null {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}
export function setAccessToken(token: string) {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
  client.defaults.headers.Authorization = `Bearer ${token}`;
}
export function clearAccessToken() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
}

export function isClientError(response: unknown) {
  return axios.isAxiosError(response);
}

export const UNKNOWN_ERROR = {
  status: 555,
  statusText: 'Unknown Error'
};

export const client: AxiosInstance = axios.create({
  baseURL: ENTRY_POINT,
  headers: {
    Authorization: `Bearer ${getAccessToken()}`,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'DELETE, POST, GET, PUT, OPTIONS',
    'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
  }
});
client.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    const message = error.response?.data.error.message || UNKNOWN_ERROR.statusText;

    if (error.response?.status === 401) {
      const token = getAccessToken();
      if (token) {
        // show error message only if token expired
        toast.error(message);

        clearAccessToken();
        window.location.replace('/');
      }
    } else {
      toast.error(message);
    }

    if (!error.response?.status) {
      const errorResponse: AxiosResponse & Pick<AxiosError, 'isAxiosError'> = {
        data: null,
        headers: error.config.headers,
        config: error.config,
        isAxiosError: true,
        errorData: null,
        ...UNKNOWN_ERROR
      };

      return Promise.resolve(errorResponse);
    }

    return Promise.resolve({ ...error, ...error.response, errorData: error.response.data || null, data: null });
  }
);
