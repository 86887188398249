import { css } from '@emotion/react';
import GoogleMapReact, { Bounds, Coords } from 'google-map-react';
import { FC, useRef, useState } from 'react';
import { GeoPosition, Location } from 'types';
import useSupercluster from 'use-supercluster';

import { useAuthState } from '../../../../store';
import { ClusterMarker, CurrentUserMarker, LocationMarker } from '../LocationMarker';

/**
 * It calculates the radius in meters from the center of the map
 * in which we're going to look for locations.
 *
 * @param zoom
 */
const getDistance = (zoom: number) => {
  // If we're zooming continent level.
  if (zoom < 4) {
    return 15000;
  }

  // If we're zooming country level.
  if (zoom < 8) {
    return 5000;
  }

  // If we're zooming city level.
  if (zoom < 15) {
    return 1000;
  }

  // Any closer we just look for Locations 500 meters around.
  return 500;
};

export enum ControlPosition {
  TOP_LEF = 1,
  TOP = 2,
  TOP_CENTER = 2,
  LEFT_CENTER = 4,
  LEF = 5,
  LEFT_TOP = 5,
  LEFT_BOTTOM = 6,
  RIGHT = 7,
  RIGHT_TOP = 7,
  RIGHT_CENTER = 8,
  RIGHT_BOTTOM = 9,
  BOTTOM_LEFT = 10,
  BOTTOM = 11,
  BOTTOM_CENTER = 11,
  BOTTOM_RIGHT = 12,
  CENTER = 13
}

export interface MapProps {
  defaultZoom?: number;
  locations: Location[];
  defaultPosition: GoogleMapReact.Coords;
  onChange: (position: GeoPosition) => void;
  onDetailedView: (id: string) => void;
}

export const Map: FC<MapProps> = props => {
  const { defaultZoom = 18, locations, defaultPosition, onChange, onDetailedView } = props;

  const { userDefaultPosition } = useAuthState();

  const [bounds, setBounds] = useState<number[] | null>(null);
  const mapRef = useRef();

  const [zoom, setZoom] = useState(defaultZoom);

  const points = locations.map(location => ({
    type: 'Feature',
    properties: { cluster: false, location },
    geometry: {
      type: 'Point',
      coordinates: [parseFloat(location.longitude), parseFloat(location.latitude)]
    }
  }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 }
  });

  const handleChange = ({ zoom, bounds, center }: { zoom: number; bounds: Bounds; center: Coords }) => {
    const data = {
      distance: getDistance(zoom),
      latitude: center.lat,
      longitude: center.lng
    };

    onChange(data);

    setZoom(zoom);
    setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
  };

  return (
    <div css={rootStyles}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: `${process.env.REACT_APP_GOOGLE_API_KEY}` }}
        defaultCenter={defaultPosition}
        defaultZoom={defaultZoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
        options={{ fullscreenControl: false, zoomControlOptions: { position: ControlPosition.RIGHT_CENTER } }}
        onChange={handleChange}
      >
        {userDefaultPosition && (
          <CurrentUserMarker
            lat={userDefaultPosition.latitude!.toString()}
            lng={userDefaultPosition.longitude!.toString()}
          />
        )}
        {clusters.map(cluster => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } = cluster.properties;

          if (isCluster) {
            return (
              <ClusterMarker
                key={`cluster-${cluster.id}`}
                onClick={() => {
                  const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20);
                  // @ts-ignore
                  mapRef.current.setZoom(expansionZoom);
                  // @ts-ignore
                  mapRef.current.panTo({ lat: latitude, lng: longitude });
                }}
                lat={latitude}
                lng={longitude}
                pointCount={pointCount}
                points={points}
              />
            );
          }

          return (
            <LocationMarker
              key={`location-${cluster.properties.location.id}`}
              lat={latitude}
              lng={longitude}
              location={cluster.properties.location}
              onClick={() => onDetailedView(cluster.properties.location.id)}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
};

const rootStyles = css`
  display: flex;

  height: 100%;
  width: 100%;
`;
