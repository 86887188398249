import 'react-spring-bottom-sheet/dist/style.css';

import { css } from '@emotion/react';
import { Button, Input, Text, useTheme } from '@shepper-tech/shepper-ui';
import React, { FC, FormEvent } from 'react';
import { useHistory } from 'react-router';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { FetchLocationsRequest, Location, Locations } from 'types';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { IconButton } from '../../../../components';
import { FormMethod } from '../../../../helpers/Form/FormMethod';
import { useForm } from '../../../../hooks';
import * as styles from '../../../Auth/components/styles';

export interface SearchProps {
  active: boolean;
  isLoading: boolean;
  locations: Location[];
}

export const Search: FC<SearchProps> = props => {
  const { active } = props;
  const history = useHistory();

  const { palette } = useTheme();

  const form = useForm<FetchLocationsRequest>({
    reference: '',
    include_visited: 1,
    include_hidden: 1
  });

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    form.submit();
  };

  form.onSubmit(() => {
    form.request<PromiseFulfilledResult<Locations>>({ method: FormMethod.GET, url: 'locations' }).then(response => {
      // @ts-ignore
      history.push(`/problems/list/${response.data[0].id}`);
    });
  });

  const reset = () => {
    form.reference = '';
  };

  return (
    <>
      <BottomSheet
        open={active}
        blocking={false}
        defaultSnap={({ lastSnap, snapPoints }) => lastSnap ?? Math.max(...snapPoints)}
        snapPoints={({ maxHeight }) => [maxHeight - maxHeight / 10, maxHeight / 4, maxHeight * 0.6]}
        header={
          <div css={headerStyles}>
            <Text css={{ fontWeight: 700 }} variant="xLargePlus">
              Search Retailer
            </Text>
            <IconButton onClick={() => history.goBack()} css={closeIconStyles}>
              <CloseIcon />
            </IconButton>
          </div>
        }
      >
        <form action="" onSubmit={handleSubmit}>
          <div css={searchFormFieldStyles}>
            <Input
              css={inputStyles}
              onChange={event => {
                form.reference = event.currentTarget.value;
              }}
              error={form.errors.first('reference')}
              inputProps={{ placeholder: 'Enter retailer ID' }}
              // @ts-ignore
              value={form.reference}
            />
            {form.errors.any('reference') && (
              <Text css={[styles.errorMessage, { color: palette.error }]} variant="medium">
                {form.errors.first('reference')}
              </Text>
            )}
          </div>
          <div css={buttonStyles}>
            <div className="clear">
              <Button color="default" size="large" fullWidth onClick={reset}>
                Clear
              </Button>
            </div>
            <div className="search">
              <Button color="primary" size="large" fullWidth type="submit">
                Search
              </Button>
            </div>
          </div>
        </form>
      </BottomSheet>
    </>
  );
};

const headerStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  position: relative;
`;

const searchFormFieldStyles = css`
  justify-content: center;
  padding: 20px 20px 10px 20px;
  width: 100%;
`;

const closeIconStyles = css`
  position: absolute;
  right: 0;
  top: -11px;
`;

const buttonStyles = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: ;
  .clear {
    flex-grow: 1;
    padding: 10px 10px 10px 20px;
  }
  .search {
    flex-grow: 1;
    padding: 10px 20px 10px 10px;
  }
`;

const inputStyles = css`
  height: 56px;
  border: 10px;
  background-color: #f7f7f7;
`;
