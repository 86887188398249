import { css } from '@emotion/react';
import { Progress, Text, Tooltip } from '@shepper-tech/shepper-ui';
import { ReactComponent as ListIcon } from 'assets/icons/list.svg';
import { ReactComponent as LogOutIcon } from 'assets/icons/log-out.svg';
import { ReactComponent as MapIcon } from 'assets/icons/map.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
// import { ReactComponent as OptionsIcon } from 'assets/icons/options.svg';
import { IconButton } from 'components';
import { FC, useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useAuthActions, useAuthState, useProblemsActions, useProblemsState } from 'store';
import { GeoPosition } from 'types';
import { useDebounce } from 'use-debounce';

import { DetailView, List, Map, Search } from './components';

export const Problems: FC = () => {
  const { view = 'map' } = useParams<{ view: 'map' | 'list' | 'search' }>();
  const history = useHistory();

  const match = useRouteMatch('/problems/:view/:id');
  const { id: locationId } = (match?.params as { id?: string }) ?? {};

  const { profile, userDefaultPosition, isUserPositionReady } = useAuthState();
  const { locations } = useProblemsState();
  const { fetchLocations } = useProblemsActions();
  const [fetchLocationsDebounced] = useDebounce(fetchLocations, 600);

  const { logOut } = useAuthActions();

  const [mapPosition, setMapPosition] = useState<GeoPosition | null>(null);

  useEffect(() => {
    if (!isUserPositionReady || !mapPosition) return;
    fetchLocationsDebounced({ ...mapPosition, per_page: 250, sort: 'distance' });
  }, [fetchLocationsDebounced, isUserPositionReady, mapPosition]);

  const isListActive = view === 'list';
  const toggleViewIcon = isListActive ? <MapIcon /> : <ListIcon />;

  const isSearchActive = view === 'search';

  const handleToggleView = () => {
    history.push(`/problems/${isListActive ? 'map' : 'list'}`);
  };

  const handleDetailedView = (id: string) => {
    history.push(`/problems/${isListActive ? 'list' : 'map'}/${id}`);
  };

  const handleSearchView = () => {
    history.push(`/problems/${isSearchActive ? 'map' : 'search'}`);
  };

  if (!userDefaultPosition) return <Progress hasOverflow message="Getting your geo position" />;

  const locationsData = locations?.data?.data || [];

  return (
    <div css={rootStyles}>
      <div css={topBar}>
        <Text css={{ fontWeight: 700 }} variant="xLarge">
          {`Reg ${profile.data?.region?.name || ''} Ter ${profile.data?.territory?.name || ''}`}
        </Text>

        <div css={iconsBtnsWrapper}>
          {/* <IconButton>
            <OptionsIcon />
          </IconButton> */}
          <div>
            <Tooltip content={`${isSearchActive ? 'Hide' : 'Show'} search view`}>
              <IconButton>
                <SearchIcon onClick={() => handleSearchView()} />
              </IconButton>
            </Tooltip>
          </div>
          <Tooltip content={`Show ${isListActive ? 'map' : 'list'} view`} place="left" globalEventOff="click">
            <IconButton onClick={() => handleToggleView()}>{toggleViewIcon}</IconButton>
          </Tooltip>
        </div>
      </div>

      <Map
        locations={locationsData}
        onChange={setMapPosition}
        defaultPosition={{ lat: userDefaultPosition.latitude, lng: userDefaultPosition.longitude }}
        onDetailedView={handleDetailedView}
      />
      <List
        active={isListActive}
        locations={locationsData}
        isLoading={locations.isLoading}
        onDetailedView={handleDetailedView}
      />
      <Search active={isSearchActive} locations={locationsData} isLoading={locations.isLoading} />

      <DetailView id={locationId} />

      <div css={bottomBar}>
        <Tooltip content="Log out" place="top">
          <IconButton onClick={() => logOut()}>
            <LogOutIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

const rootStyles = css`
  position: relative;

  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;
`;

const topBar = css`
  z-index: 1;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  padding: 1em 1em 6em 1em;
  background: linear-gradient(#fff, 85%, rgba(255, 255, 255, 0));
`;

const bottomBar = css`
  z-index: 1;
  position: absolute;
  left: 1em;
  bottom: 1em;
`;

const iconsBtnsWrapper = css`
  display: flex;
  gap: 16px;
`;
