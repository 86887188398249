import { URLS } from 'consts/api';
import {
  LoginRequest,
  LoginResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  SendPasswordResetRequest
} from 'types';
import { client } from 'utils';

export async function logIn(request: LoginRequest) {
  return client.post<LoginResponse>(URLS.AUTH.POST_LOGIN, request);
}

export async function logOut() {
  return client.post(URLS.AUTH.POST_LOGOUT);
}

export async function sendPasswordReset(request: SendPasswordResetRequest) {
  return client.post(URLS.AUTH.POST_SEND_PASSWORD_RESET, request);
}

export async function resetPassword(request: ResetPasswordRequest) {
  return client.post<ResetPasswordResponse>(URLS.AUTH.POST_RESET_PASSWORD, request);
}
