import { URLS } from 'consts/api';
import { GeoPosition, Profile } from 'types';
import { client } from 'utils';

export async function getProfile() {
  return client.get<Profile>(URLS.ACCOUNT.GET_PROFILE);
}

export async function updatePosition(position: Omit<GeoPosition, 'distance'>) {
  return client.put(URLS.ACCOUNT.UPDATE_POSITION, position);
}
