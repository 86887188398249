import { css } from '@emotion/react';
import { Progress, ThemeProvider } from '@shepper-tech/shepper-ui';
import { Auth, Problems } from 'pages';
import { FC, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useAuthActions, useAuthState } from 'store';
import { getLoadable } from 'store/helpers';
import { useCalcWindowHeight, WINDOW_HEIGHT_CSS_VAR } from 'utils';

import { Layout } from './components';
import { customTheme } from './theme';

const authRouting = (
  <Auth.Layout>
    <Switch>
      <Route path="/auth/login" component={Auth.LogIn} />
      <Route path="/auth/forgot-password" component={Auth.ForgotPassword} />
      <Route path="/auth/reset-password/:token" component={Auth.ResetPassword} />

      <Redirect to="/auth/login" />
    </Switch>
  </Auth.Layout>
);

const mainRouting = (
  <Layout>
    <Switch>
      <Route path="/problems/:view" component={Problems} />

      <Redirect to="/problems/map" />
    </Switch>
  </Layout>
);

export const App: FC = () => {
  const { isLoggedIn, profile } = useAuthState();
  const { fetchProfile, setState } = useAuthActions();
  const routing = isLoggedIn ? mainRouting : authRouting;

  useCalcWindowHeight();

  useEffect(() => {
    if (window.location.pathname.includes('/auth/reset-password/')) {
      setState({ key: 'profile', value: getLoadable() });
    } else {
      fetchProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = profile.isLoading ? (
    <Progress hasOverflow />
  ) : (
    <Router>
      {routing}
      <Toaster />
    </Router>
  );

  return (
    <ThemeProvider theme={customTheme} styles={globalStyles}>
      {content}
    </ThemeProvider>
  );
};

const globalStyles = css`
  #root {
    height: var(${WINDOW_HEIGHT_CSS_VAR});
    width: 100vw;
  }
`;
