import { URLS } from 'consts/api';
import { FetchLocationsRequest, FixLocationRequest, Location, Locations } from 'types';
import { client } from 'utils';

export async function getLocations(request: FetchLocationsRequest) {
  return client.get<Locations>(URLS.LOCATIONS.GET_LOCATIONS, { params: request });
}

export async function getLocation(id: string) {
  const url = URLS.LOCATIONS.GET_LOCATION.replace('$locationId', id);
  return client.get<Location>(url);
}

export async function fixLocation(id: string, request: FixLocationRequest) {
  const url = URLS.LOCATIONS.POST_FIX_LOCATION.replace('$locationId', id);
  return client.post<Location>(url, request);
}

export async function flagIncorrectLocation(id: string) {
  const url = URLS.LOCATIONS.POST_FLAG_INCORRECT_LOCATION.replace('$locationId', id);
  return client.post<Location>(url);
}
