import { css } from '@emotion/react';
import { FC } from 'react';

interface CurrentUserMarkerProps {
  lat: string;
  lng: string;
}

export const CurrentUserMarker: FC<CurrentUserMarkerProps> = props => {
  const { lat, lng } = props;

  return <div data-lat={lat} data-lng={lng} css={rootStyles} />;
};

const rootStyles = css`
  color: #fff;
  background: #3f81ec;
  font-family: Effra;
  border: 2px solid #fff;
  font-weight: bold;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
