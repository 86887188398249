import { css } from '@emotion/react';
import { Image, Text } from '@shepper-tech/shepper-ui';
import { FC } from 'react';
import { LocationProblem as ProblemData } from 'types';

import { formatProblemType } from './utils';

export interface ProblemProps {
  data: ProblemData;
}
export const Problem: FC<ProblemProps> = props => {
  const { data } = props;

  return (
    <div css={rootStyles}>
      <Text variant="large" css={{ fontWeight: 700 }}>
        {formatProblemType(data.type)}
      </Text>
      <Image css={imgStyles} src={data.image_url} alt={`${data.type}-${data.id}`} />
    </div>
  );
};

const rootStyles = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const imgStyles = css`
  max-height: 500px;
  border-radius: 6px;
  object-fit: cover;
`;
