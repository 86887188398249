import { Button, ButtonColor, Input, Text, useTheme } from '@shepper-tech/shepper-ui';
import { FC, FormEvent } from 'react';

import * as styles from '../styles';
import { ForgotPasswordForm } from '../types';

export interface ForgotPasswordProps {
  form: ForgotPasswordForm;
  onGoToLoginPageClick: () => void;
}

export const ForgotPassword: FC<ForgotPasswordProps> = props => {
  const { form, onGoToLoginPageClick } = props;

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    form.submit();
  };

  const { palette } = useTheme();

  return (
    <>
      <Text css={styles.titleStyles} variant="xxLargePlus">
        Reset Password
      </Text>

      <Text css={styles.fieldWrapper}>We’ll send a link to the specified email to reset your password</Text>

      <form css={styles.formStyles} onSubmit={handleSubmit}>
        <div css={styles.fieldWrapper}>
          <Input
            css={styles.inputStyles}
            onChange={event => {
              form.email = event.currentTarget.value;
            }}
            value={form.email}
            error={form.errors.first('email')}
            inputProps={{ placeholder: 'Enter your email address' }}
          />
          {form.errors.any('email') && (
            <Text css={[styles.errorMessage, { color: palette.error }]} variant="medium">
              {form.errors.first('email')}
            </Text>
          )}
        </div>

        <Button
          type="submit"
          color={ButtonColor.primary}
          fullWidth
          disabled={form.isProcessing()}
          css={styles.actionButtonStyles}
        >
          Send a link
        </Button>
        <Button disabled={form.isProcessing()} css={styles.secondaryButtonStyles} onClick={onGoToLoginPageClick}>
          Go to Log in page
        </Button>
      </form>
    </>
  );
};
