import { useForm } from 'hooks';
import { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAuthActions } from 'store';
import {
  LoginRequest,
  LoginResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  SendPasswordResetRequest
} from 'types';

import {
  ForgotPassword as ForgotPasswordCard,
  ForgotPasswordForm,
  Layout,
  LogIn as LogInCard,
  LogInForm,
  ResetPassword as ResetPasswordCard,
  ResetPasswordForm
} from './components';

const LogIn: FC = () => {
  const { logIn } = useAuthActions();
  const form = useForm<LoginRequest>({
    email: '',
    password: ''
  });

  form.onSubmit(() => {
    form.request<LoginResponse, LoginRequest>({ service: logIn });
  });

  const history = useHistory();

  return <LogInCard onForgotLoginClick={() => history.push('forgot-password')} form={form as LogInForm} />;
};

const ForgotPassword: FC = () => {
  const { sendPasswordReset } = useAuthActions();

  const history = useHistory();

  const form = useForm<SendPasswordResetRequest>({
    email: ''
  });

  const goToLoginPage = () => {
    history.push('/auth/login');
  };

  form.onSubmit(() => {
    form.request<never, SendPasswordResetRequest>({ service: sendPasswordReset }).then(goToLoginPage);
  });

  return <ForgotPasswordCard onGoToLoginPageClick={goToLoginPage} form={form as ForgotPasswordForm} />;
};

const ResetPassword: FC = () => {
  const { resetPassword } = useAuthActions();

  const { token } = useParams<{ token: string }>();

  const form = useForm<ResetPasswordRequest>({
    email: '',
    password: '',
    token
  });

  form.onSubmit(() => {
    form.request<ResetPasswordResponse, ResetPasswordRequest>({ service: resetPassword });
  });

  return <ResetPasswordCard form={form as ResetPasswordForm} />;
};

export const Auth = {
  LogIn,
  Layout,
  ForgotPassword,
  ResetPassword
};
