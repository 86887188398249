import 'react-spring-bottom-sheet/dist/style.css';

import { css } from '@emotion/react';
import { Button, Text } from '@shepper-tech/shepper-ui';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { IconButton } from 'components';
import { ChangeEvent, FC, FormEvent, useRef, useState } from 'react';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';

export interface NotesProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (notes: string) => void;
}

export const Notes: FC<NotesProps> = props => {
  const { isOpen, onClose, onSubmit } = props;

  const sheetRef = useRef<BottomSheetRef>(null);
  const focusRef = useRef<HTMLButtonElement>(null);

  const [notes, setNotes] = useState('');
  const handleChangeNotes = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(event.target.value);
  };
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(notes);
    setNotes('');
    onClose();
  };

  return (
    <BottomSheet
      open={isOpen}
      ref={sheetRef}
      initialFocusRef={focusRef}
      defaultSnap={({ snapPoints }) => Math.max(...snapPoints)}
      snapPoints={({ maxHeight }) => [maxHeight - maxHeight / 10]}
      header={
        <div css={headerStyles}>
          <Text css={{ fontWeight: 700 }} variant="xLargePlus">
            Notes
          </Text>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      }
      footer={
        <Button
          css={btnStyles}
          color="primary"
          size="large"
          fullWidth
          disabled={notes.length === 0}
          onClick={handleSubmit}
        >
          Submit notes
        </Button>
      }
    >
      <div css={rootStyles}>
        <Text css={{ color: '#8A8A8A' }} variant="large">
          Enter notes about your visit
        </Text>
        <textarea css={notesTextarea} value={notes} onChange={handleChangeNotes} />
      </div>
    </BottomSheet>
  );
};

const rootStyles = css`
  display: flex;
  flex-direction: column;
  padding: 18px;
  height: 100%;
  gap: 24px;
`;

const headerStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
`;

const btnStyles = css`
  padding: 18px;
`;

const notesTextarea = css`
  height: 500px;
  width: 100%;

  padding: 10px;
  border-radius: 2px;
  border: none;
  outline: none;

  font-size: 18px;
  font-family: 'Gellix';

  background: #dfe2e2;

  resize: vertical;
`;
