export const variadic = (args: Array<string | string[]>): string[] => {
  if (Array.isArray(args[0])) {
    return args[0];
  }

  return args as string[];
};

const matchOperatorsRe = /[|\\{}()[\]^$+*?.]/g;

export const escapeRegexString = (pattern: string) => {
  if (typeof pattern !== 'string') {
    throw new TypeError('Expected a string');
  }

  return pattern.replace(matchOperatorsRe, '\\$&');
};

export const patternToRegExp = (pattern: string) => {
  // eslint-disable-next-line no-param-reassign
  pattern = escapeRegexString(pattern.replace(/\*/g, '__WILDCARD__'));

  return new RegExp(`^${pattern.replace(/__WILDCARD__/g, '.+')}$`);
};
