import { css } from '@emotion/react';
import { FC } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LayoutProps {}

export const Layout: FC<LayoutProps> = props => {
  const { children } = props;

  return <div css={rootStyles}>{children}</div>;
};

const rootStyles = css`
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;
`;
