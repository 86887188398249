import { useEffect } from 'react';

export const WINDOW_HEIGHT_CSS_VAR = '--window-height';

function calcWindowHeight() {
  document.documentElement.style.setProperty(WINDOW_HEIGHT_CSS_VAR, `${window.innerHeight}px`);
}

/*
 * Handles calculation of the browser's window height and store it into css variable
 * which is available from the WINDOW_HEIGHT_CSS_VAR const. It's handy for mobile devices.
 * IMPORTANT: use this hook only in the one place e.g. root component
 */
export function useCalcWindowHeight() {
  useEffect(() => {
    calcWindowHeight();
    window.addEventListener('resize', calcWindowHeight);

    return () => {
      window.removeEventListener('resize', calcWindowHeight);
    };
  }, []);
}
