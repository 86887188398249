import { useStoreActions, useStoreState } from 'easy-peasy';
import { StoreActionsType, StoreStateType } from 'store/types';

export const useAuthState = () => {
  return useStoreState((store: StoreStateType) => store.auth);
};

export const useAuthActions = () => {
  return useStoreActions((store: StoreActionsType) => store.auth);
};
