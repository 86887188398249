import { Action, action } from 'easy-peasy';

import { ILoadable, ISetStatePayload } from './types';

// eslint-disable-next-line @typescript-eslint/ban-types
export function getSimpleSetState<M extends object>(): Action<M, ISetStatePayload<M>> {
  return action((state, payload) => {
    const { key, value } = payload;
    // eslint-disable-next-line no-param-reassign
    state[key] = value as never;
  });
}

export function getLoadable<T>(payload: Partial<ILoadable<T>> = {}): ILoadable<T> {
  const { data, isLoading } = payload;

  return {
    data: data ?? null,
    isLoading: isLoading ?? false
  };
}
