import 'react-spring-bottom-sheet/dist/style.css';

import { css, Global } from '@emotion/react';
import { Progress } from '@shepper-tech/shepper-ui';
import { FC, useRef } from 'react';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';
import { Location } from 'types';

import { LocationCard } from '../LocationCard';

export interface ListProps {
  active: boolean;
  locations: Location[];
  isLoading: boolean;
  onDetailedView: (id: string) => void;
}
export const List: FC<ListProps> = props => {
  const { active, locations, isLoading, onDetailedView } = props;

  const sheetRef = useRef<BottomSheetRef>(null);
  const focusRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Global styles={bottomSheetStyles} />
      <BottomSheet
        open={active}
        blocking={false}
        ref={sheetRef}
        initialFocusRef={focusRef}
        defaultSnap={({ lastSnap, snapPoints }) => lastSnap ?? Math.max(...snapPoints)}
        snapPoints={({ maxHeight }) => [maxHeight - maxHeight / 10, maxHeight / 4, maxHeight * 0.6]}
      >
        <div css={rootStyles}>
          {isLoading ? (
            <Progress css={{ marginTop: '4em' }} />
          ) : (
            locations.map(location => (
              <LocationCard key={location.id} data={location} onDetails={() => onDetailedView(location.id)} />
            ))
          )}
        </div>
      </BottomSheet>
    </>
  );
};

const rootStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px;
  gap: 24px;
`;

const bottomSheetStyles = css`
  :root {
    --rsbs-max-w: 600px;
    --rsbs-ml: auto;
    --rsbs-mr: auto;
    --rsbs-overlay-rounded: 24px;
  }
`;
