import { Form, IFormData } from 'helpers';
import { useState } from 'react';

export const useForm = <D = IFormData>(data: D): Form<D> => {
  // Initialize the form with the given data and get the state
  // getter and setter of the form to use the latter to trigger
  // re-renders when data changes.
  const [form, setForm] = useState<Form<D>>(new Form<D>(data));

  return new Proxy(form, {
    /**
     * Intercepting form assignments, e.g. form.email = 'value'.
     *
     * We look up the property being assigned on our form.data store
     * first and assign it there instead. So in this case 'value'
     * will be assigned to form.data.email instead of form.email.
     *
     * Then we trigger a component re-render.
     *
     * @param {Form} target
     * @param {string} field
     * @param {any} value
     */
    set(target: Form<D>, field: string, value) {
      // If the field being assigned exists on the form.data store
      // we assign it there instead.
      if (form.data[field as keyof D] !== undefined) {
        // Assign data to the form data store.
        form.data[field as keyof D] = value;

        // Trigger component re-render.
        setForm(form.copy());
      } else {
        // If we are not assigning form data we do a normal assigment.
        form[field] = value;
      }

      // If the form.processing value changes we trigger a re-render
      // useful to update the component view and disable submit
      // buttons when the form is processing.
      if (field === 'processing') {
        setForm(form.copy());
      }

      // Return true to indicate the assignment has succeeded.
      return true;
    },

    /**
     * Intercepts the properties being accessed.
     *
     * We look up the property being accessed on our form.data store
     * first and return its value if we found it.
     *
     * @param {Form} target
     * @param {string} field
     */
    get(target: Form<D>, field: string) {
      // If the field being accessed exists on our form.data store
      // we return that instead.
      if (form[field] === undefined) {
        return form.data[field as keyof D];
      }

      // If not, we just return the requested property.
      return form[field];
    }
  });
};
