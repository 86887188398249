/* eslint-disable camelcase */

import { GeoPosition } from './account';
import { ISODateTime, Paginated, PaginatedRequest, URL } from './helpers';

export interface LocationProblem {
  id: number;
  object: 'location.problem';
  type: string;
  image_url: URL;
}

export enum VisitStatus {
  required = 'required',
  incorrectLocation = 'incorrect_location',
  notVisited = 'not_visited',
  completed = 'completed'
}

export interface User {
  id: number;
  first_name: string;
  last_name: string;
}

export interface Visit {
  id: number;
  object: 'location.visit';
  status: VisitStatus;
  notes?: string;
  ended_at?: ISODateTime;
  problems: LocationProblem[];
  user: User;
}

export interface Location {
  id: string;
  object: 'location';
  score: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  reference: number;
  image_url: URL;
  last_reported_at: ISODateTime;
  latitude: string;
  longitude: string;
  business_name: string;
  region: string;
  address: string;
  visit?: Visit;
}

export type FetchLocationsRequest = PaginatedRequest & {
  reference?: string;
  distance?: number;
  latitude?: number;
  longitude?: number;
  include_visited?: 1 | 0;
  include_hidden?: 1 | 0;
};
export type Locations = Paginated<Location>;

export interface FixLocationRequest extends Omit<GeoPosition, 'distance'> {
  notes: string;
}
