import { patternToRegExp, variadic } from './utils';

interface IErrors {
  [key: string]: string[];
}

export class Errors {
  private errors: IErrors = {};

  /**
   * @param {IErrors} errors
   */
  public constructor(errors: IErrors = {}) {
    this.record(errors);
  }

  /**
   * @param {IErrors} errors
   */
  public record(errors: IErrors = {}) {
    this.errors = errors;
  }

  /**
   * Determine if the collection has errors for given fields.
   *
   * @param {array|string} fields
   * @return {boolean}
   */
  public has(...fields: Array<string | string[]>): boolean {
    const properties = variadic(fields);

    return properties.find((key: string) => this.matching(key).length === 0) === undefined;
  }

  /**
   * Determine if the collection has errors for given fields.
   *
   * @param {array|string} fields
   * @return {boolean}
   */
  public any(...fields: Array<string | string[]>): boolean {
    const properties = variadic(fields);

    return properties.filter((key: string) => this.matching(key).length > 0).length > 0;
  }

  /**
   * Return an array of error keys matching given pattern.
   *
   * @param {string} pattern
   * @return {string[]}
   */
  public matching(pattern: string): string[] {
    const regexp = patternToRegExp(pattern);

    return this.keys().filter(key => regexp.test(key));
  }

  /**
   * Return all the errors from given field.
   *
   * @param {string} field
   */
  public get(field: string): string[] {
    return this.errors[field] || [];
  }

  /**
   * Return the first error from given fields.
   *
   * @param {array|string} fields
   */
  public first(...fields: string[]): string | undefined {
    const matching = fields.reduce((carry: string[], field: string) => {
      return carry.concat(this.matching(field));
    }, []);

    return this.get(matching[0] || fields[0])[0];
  }

  /**
   * @return {IErrors}
   */
  public all(): IErrors {
    return this.errors;
  }

  /**
   * Return all the error keys.
   *
   * @return {string[]}
   */
  public keys(): string[] {
    return Object.keys(this.errors);
  }

  /**
   * Determine if the error collection is not empty.
   *
   * @return {boolean}
   */
  public isNotEmpty(): boolean {
    return this.isEmpty() === false;
  }

  /**
   * Determine if the error collection is empty.
   *
   * @returns {boolean}
   */
  public isEmpty(): boolean {
    return Object.values(this.errors).length === 0;
  }

  /**
   * Clear a specific field, object or all error fields.
   *
   * @param {string|undefined} field
   */
  public clear(field?: string): void {
    if (!field) {
      // Clear all the errors if field hasn't been specified.
      this.errors = {};

      return;
    }

    // Get a copy of the error collection.
    const errors = { ...this.errors };

    // Grab the error keys matching the given field.
    const matching = this.matching(field);

    // Remove the errors containing the matched keys from the error
    // collection copy.
    this.keys()
      .filter((key: string) => matching.includes(key))
      .forEach((key: string) => delete errors[key]);

    // Replace the error collection with the copy.
    this.errors = errors;
  }
}

export default Errors;
