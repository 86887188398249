import { css } from '@emotion/react';

const titleStyles = css`
  font-weight: 700;
  margin-bottom: 28px;
`;

const formStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const fieldWrapper = css`
  margin-bottom: 16px;
  width: 100%;
`;

const inputStyles = css`
  height: 56px;
  border: none;
  background-color: #f7f7f7;
`;

const errorMessage = css`
  margin-top: 0.25em;
`;

const actionButtonStyles = css`
  font-size: 18px;
  padding-top: 18px;
  padding-bottom: 18px;
`;

const secondaryButtonStyles = css`
  margin-top: 0.5em;
  align-self: center;
  border: none;
  font-size: 14px;
  color: #8a8a8a;
`;

export { actionButtonStyles, errorMessage, fieldWrapper, formStyles, inputStyles, secondaryButtonStyles, titleStyles };
