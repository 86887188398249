import { UPDATE_USER_POSITION_INTERVAL } from 'consts';
import { Computed, computed, Thunk, thunk } from 'easy-peasy';
import toast from 'react-hot-toast';
import { accountService, authService } from 'services';
import { getLoadable, getSimpleSetState } from 'store/helpers';
import { ILoadable, ISimpleModel } from 'store/types';
import { GeoPosition, LoginRequest, Profile, ResetPasswordRequest, SendPasswordResetRequest } from 'types';
import { clearAccessToken, getCurrentPosition, setAccessToken } from 'utils';

export interface AuthModel extends ISimpleModel<AuthModel> {
  error?: string;
  isLoggedIn: Computed<AuthModel, boolean>;
  logIn: Thunk<AuthModel, LoginRequest>;
  sendPasswordReset: Thunk<AuthModel, SendPasswordResetRequest>;
  resetPassword: Thunk<AuthModel, ResetPasswordRequest>;
  logOut: Thunk<AuthModel>;
  profile: ILoadable<Profile>;
  fetchProfile: Thunk<AuthModel>;
  userDefaultPosition: GeoPosition | null;
  updateUserPosition: Thunk<AuthModel, undefined | { interval: number }>;
  isUserPositionReady: Computed<AuthModel, boolean>;
}

export const auth: AuthModel = {
  setState: getSimpleSetState<AuthModel>(),

  isLoggedIn: computed(state => Boolean(state.profile.data)),

  logIn: thunk(async (actions, payload) => {
    const response = await authService.logIn(payload);

    if (response.data) {
      setAccessToken(response.data.access_token);
      actions.fetchProfile();
    }

    return response;
  }),

  sendPasswordReset: thunk(async (actions, payload) => {
    const response = await authService.sendPasswordReset(payload);

    if (response.status === 204) {
      toast.success('Link successfully sent, please check your email');
    }

    return response;
  }),

  resetPassword: thunk(async (actions, payload) => {
    const response = await authService.resetPassword(payload);

    if (response.data) {
      setAccessToken(response.data.access_token);
      actions.fetchProfile();
    }

    return response;
  }),

  logOut: thunk(async actions => {
    const response = await authService.logOut();

    if (response.status === 204) {
      clearAccessToken();
      actions.setState({ key: 'profile', value: getLoadable() });
    }
  }),

  profile: getLoadable({ isLoading: true }),

  fetchProfile: thunk(async actions => {
    actions.setState({ key: 'profile', value: getLoadable({ isLoading: true }) });

    const response = await accountService.getProfile();

    const data = response.data ?? null;

    if (data) actions.updateUserPosition();

    actions.setState({ key: 'profile', value: getLoadable({ data }) });
  }),

  userDefaultPosition: null,

  updateUserPosition: thunk(async (actions, payload) => {
    const interval = payload ? payload.interval : null;

    const cb = () => {
      getCurrentPosition()
        .then(({ coords }) => {
          const position = { latitude: coords.latitude, longitude: coords.longitude };

          accountService.updatePosition(position).then(() => {
            if (interval) return;

            actions.setState({
              key: 'userDefaultPosition',
              value: position
            });
          });
        })
        .catch(error => {
          toast.error('Unable to get your current location');

          // eslint-disable-next-line no-console
          console.warn({ error });
        })
        .finally(() => actions.updateUserPosition({ interval: UPDATE_USER_POSITION_INTERVAL }));
    };

    if (!interval) {
      cb();
    } else {
      setTimeout(cb, interval);
    }
  }),

  isUserPositionReady: computed(state => Boolean(state.userDefaultPosition))
};
